import {onFind} from "@elements/init-modules-in-scope";
import {activateScrollLock, deactivateScrollLock} from "@elements/scroll-lock";

const defaultSelectors = {
    base: '.js-room-plan-sidebar__toggle',
    overlay: 'room-plan-sidebar__',
    close: '.js-room-plan-sidebar__close',
};

export function init(selectors = defaultSelectors) {
    onFind(selectors.base, function (baseElement) {
        console.log("baseElement", baseElement);
        let room = document.getElementById(selectors.overlay+baseElement.id);
        console.log("room", room);

        baseElement.addEventListener('click', () => {
            room.classList.add('is-open');
            //activateScrollLock();
        });
        onFind(selectors.close, function (closeElement) {
            closeElement.addEventListener('click', () => {
                room.classList.remove('is-open');
                //deactivateScrollLock();
            })
        });
    });
}